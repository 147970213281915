import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { useGetCountriesQuery } from '../../../components/api'
// import { useGetCompaniesQuery } from '../../../components/api/AccountsApix'
import Layout from '../../../components/Layout'
import MUIDataTable from '../../../components/MuiDatatableUtils'
import { unauthNavTo } from '../../../components/navigation'
import NotificationToaster from '../../../components/NotificationToaster'
import Spinner from '../../../components/spinner'
import VendorDeleteGroupsModal from '../../../pages/app/kitchensink/VendorDeleteGroups'
import {
    useCreateGroup,
    useDeleteGroups,
    useGetCompaniesQuery,
    useGetGroupsQuery,
    useGetuserDetailsQuery
} from '../../../utils/api'

const Page = (props) => {
  const { location: { state = {} } = {} } = props
  const { status = '', message = '', date = '' } = state === null ? {} : state
  // ANCHOR state
  const [selectedRowIndexes, setSelectedRowIndexes] = React.useState([])
  const [openDeleteParticipantModal, setOpenDeleteParticipantModal] =
    React.useState(false)
  const [selectedParticipants, setSelectedParticipants] = React.useState([])
  const [showInviteCompanyModal, setShowInviteCompanyModal] =
    React.useState(false)
  const [tabChanged, setTabChanged] = React.useState(false)
  const [tabSelectedIndex, setTabSelectedIndex] = React.useState(0)
  const [filteredData, setFilteredData] = React.useState('')
  const [isShowToaster, setIsShowToaster] = React.useState(false)
  const [isShowDeleteToaster, setIsShowDeleteToaster] = React.useState(false)
  const deselectAll = () => {
    setSelectedRowIndexes([])
  }

  // ANCHOR query
  const companiesQuery = useGetCompaniesQuery()
  const groupsQuery = useGetGroupsQuery()
  const deleteGroups = useDeleteGroups()
  const countriesQuery = useGetCountriesQuery()
  const createGroupMutation = useCreateGroup()

  React.useEffect(() => {
    // compare date and current time to see if the toaster should be shown

    if (status === 'success' && date !== '' && new Date().getTime() - new Date(date).getTime() < 2000) {
      setIsShowToaster(true)
    //   console.log('browser type', { props, state })
    }
  }, [])
  React.useEffect(() => {
    if (deleteGroups.isSuccess) {
      setIsShowDeleteToaster(true)
    }
  }, [deleteGroups.isSuccess])
  const { data: { _isVendor = false } = {} } = useGetuserDetailsQuery()

  const goPage = (x) => {
    unauthNavTo.viewParticipantPage(x)
  }

  // ANCHOR functions

  const filterBaseOnTab = (e) => {
    const companyList = companiesQuery.data
    switch (e.target.innerText) {
      case 'All':
        setFilteredData(companyList)
        setTabSelectedIndex(0)
        break
      case 'Active':
        setFilteredData(companyList.filter((c) => c.status === 'active'))
        setTabSelectedIndex(1)
        break
      case 'Suspended/Declined':
        setFilteredData(companyList.filter((c) => c.status === 'suspended' || c.status === 'declined'))
        setTabSelectedIndex(3)
        break
      case 'Invited/Applied':
        setFilteredData(companyList.filter((c) => c.status === 'invited' || c.status === 'applied'))
        setTabSelectedIndex(2)
        break
      default:
        break
    }
    setTabChanged(true)
  }

  // ANCHOR React <>
  return (
    <>
      <Layout>
        <Layout.Title>
          <h5>Group Management</h5>
          <Button
            variant="primary"
            className="d-flex align-items-center"
            onClick={() => {
              unauthNavTo.createGroup()
            }}
          >
            Create a Group
          </Button>
        </Layout.Title>
        <Layout.CardGrey>
        <NotificationToaster
        showToast={isShowDeleteToaster}
        setShowToasFn={setIsShowDeleteToaster}
        isSuccess={true}
        message={
             'Group deleted. \xa0\xa0\xa0\xa0\xa0'

        }
      />
        <NotificationToaster
        showToast={isShowToaster}
        setShowToasFn={setIsShowToaster}
        isSuccess={true}
        message={
             message || 'Group Created! \xa0\xa0\xa0\xa0\xa0'

        }
      />
          <div className="card-body rounded-soft bg-white">
            <div className="container-fluid mb-4">
              {/* ANCHOR Table */}
              {companiesQuery.isLoading && (
                <div className="d-flex justify-content-center p-5">
                  <Spinner />
                </div>
              )}
              {companiesQuery.isError && (
                <Alert variant="danger" className="mb-0 mt-3 text-center">
                  {' ' + companiesQuery.error.message}
                </Alert>
              )}
              {companiesQuery.isSuccess && groupsQuery.isSuccess && (
                <MUIDataTable
                  columns={[

                    { label: 'Group Name', name: 'groupName' },
                    { label: 'Number of Participants', name: 'numberOfParticipants' },

                    {
                      label: ' ',
                      name: '_id',
                      options: {
                        customBodyRender: (x, tableMeta) => {
                          return (
                            <div className="d-flex justify-content-end">
                              <Button
                                theme="outline-primary"
                                size="sm"
                                disabled={!(tableMeta.rowData[0] !== 'invited') || tableMeta.rowData[0] === 'All'}
                                onClick={() => {
                                  const groupData = groupsQuery.data.filter((c) => c.name === tableMeta.rowData[0])
                                  unauthNavTo.editGroup({ groupData: groupData[0] })
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                          )
                        }
                      }
                    },
                    {
                      lable: ' ',
                      name: '_id',
                      options: {
                        display: false
                      }
                    }
                  ]}
                  data={
                    [
                      {
                        groupName: 'All',
                        numberOfParticipants: companiesQuery.data.filter((c) => c.status === 'active').length
                      },
                      ...groupsQuery.data?.sort((a, b) =>
                        a.name.localeCompare(b.name, undefined, {
                          sensitivity: 'base'
                        })
                      ).map((c) => {
                        return {
                          groupName: c.name,
                          numberOfParticipants: c.groupMembers?.length || 0,
                          _id: c._id
                        }
                      })

                    ]
                  }
                  options={{
                    pagination: true,
                    jumpToPage: false,
                    print: false,
                    search: true,
                    download: true,
                    sort: true,
                    filter: true,
                    viewColumns: true,
                    selectableRows: _isVendor ? 'multiple' : 'none',
                    elevation: 0,
                    rowsPerPage: 25,
                    isRowSelectable: dataIndex => (dataIndex !== 0), // disable the first row : ALL participants
                    rowsSelected: selectedRowIndexes,
                    onRowSelectionChange: (
                      currentRowsSelected,
                      allRowsSelected,
                      rowsSelected
                    ) => {
                      if (
                        selectedRowIndexes.join(',') !== rowsSelected.join(',')
                      ) {
                        setSelectedRowIndexes(rowsSelected)
                      }
                    },
                    customToolbarSelect: (selectedRows, displayData) => {
                      // defer loading selected SKUs for performance reasons...
                      const getSelectedParticipants = () =>
                        selectedRows.data.map(
                          (d) => {
                            return displayData[d.index].data[3]
                          }
                        )
                      return (
                        <div className="custom-toolbar-select flex-mobile-column">
                            <Button
                            variant="outline-primary"
                            className="btn-sm mr-2"
                            disabled={selectedRows.data.length === 0 || selectedRows.data.length > 1}
                            onClick={() => {
                              const groupData = groupsQuery.data.filter((c) => c.name === displayData[selectedRows.data[0].index].data[0])
                              groupData[0].isDuplicate = true
                              unauthNavTo.editGroup({ groupData: groupData[0] })
                            }}
                        >
                            Duplicate
                        </Button>
                          {_isVendor && (

                            <Button
                              variant="outline-primary"
                              className="btn-sm mr-2"
                              onClick={() => {
                                setOpenDeleteParticipantModal(true)
                                setSelectedParticipants(
                                  getSelectedParticipants()
                                )
                                deselectAll()
                              }}
                            >
                              Delete
                            </Button>
                          )}
                        </div>
                      )
                    }
                  }}
                />
              )}
            </div>
          </div>

          {/* { process.env.NODE_ENV !== 'production' && <pre>countriesQuery={JSON.stringify(countriesQuery, null, 2)}</pre>} */}
          {process.env.NODE_ENV !== 'production' && (
            <pre>companiesQuery={JSON.stringify(companiesQuery, null, 2)}</pre>
          )}
        </Layout.CardGrey>
      </Layout>

      {/* ANCHOR Modals */}

      <VendorDeleteGroupsModal
      openModal={openDeleteParticipantModal}
      setOpenModal={setOpenDeleteParticipantModal}
      participants={selectedParticipants}
      deleteGroups={deleteGroups}
      />
    </>
  )
}

export default Page
